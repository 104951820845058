<template>
  <!-- <?xml version="1.0" encoding="utf-8"?> -->
  <!-- Generator: Adobe Illustrator 26.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Capa_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 595.3 165.5"
    style="enable-background: new 0 0 595.3 165.5"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M194.1,108.6c-13.1,0-24.5-2.9-26.4-14.2h10.4c1.9,5.3,8.4,6.1,17.2,6.1c10.7,0,16.2-1.8,16.2-7.5v-0.7c0-2-0.7-3.5-2-4.5
			s-3.9-1.5-7.7-1.7l-15-0.6c-12.5-0.5-17.5-4.7-17.5-13.4v-0.5c0-11.3,8.4-15.8,24.1-15.8h1.6c12.7,0,23.3,3.4,25.4,14.5h-10.4
			c-1.5-5.2-7.7-6.5-16.2-6.5c-10.8,0-14.5,2.2-14.5,6.9v0.3c0,2,0.7,3.5,1.9,4.5c1.3,0.9,3.9,1.5,7.8,1.7l15,0.5
			c12.5,0.5,17.4,4.7,17.4,13.4V92c0,12.7-10.1,16.6-25.7,16.6H194.1z"
        />
        <path
          d="M228.2,69.1h9v22.5c0,7.8,4.3,9.1,10,9.1c3.5,0,6.3-0.7,8.2-2.2c2-1.5,3.1-4.4,3.1-8.5V69.1h9.1v38.7h-9.1v-6.6h-0.4
			c-1.8,4.3-5.9,7.3-13.8,7.3c-9.4,0-16.2-3.4-16.2-15.1V69.1z"
        />
        <path
          d="M314.9,107.8h-9V85.3c0-7.8-4.2-9.1-10-9.1c-3.5,0-6.3,0.7-8.3,2.3c-2,1.5-3,4.3-3,8.5v20.9h-9.1V69.1h9.1v6.6h0.4
			c2-4.3,5.9-7.3,13.8-7.3c9.5,0,16.2,3.3,16.2,15.1V107.8z"
        />
        <path
          d="M395.4,107.8h-9.8V65.4h-0.4l-20.7,42.4h-9.8l-20.5-42.4h-0.4v42.4h-9.8V56.7h17.3l18.6,38.6h0.4l18.8-38.6h16.3V107.8z"
        />
        <path
          d="M444.3,96.7c-2,7.7-7.9,11.9-20,11.9H423c-13,0-20.6-4.9-20.6-19.1v-2c0-14.2,7.6-19.1,20.6-19.1h1.2
			c13,0,20.5,4.7,20.5,18.8v3.3h-33.1c0.3,9.3,4.7,10.8,12.1,10.8c6.4,0,9.8-1.1,11.5-4.7H444.3z M411.7,84.4h24v-0.3
			c0-3.5-1.1-5.9-3.4-7.2c-2.2-1.2-5.1-1.9-8.7-1.9C416.7,75,412.2,76.4,411.7,84.4z"
        />
        <path
          d="M493.3,107.8h-9v-6.1h-0.3c-2,4.3-5.9,6.9-14.4,6.9h-1.1c-11.2,0-19.2-4.3-19.2-18.8v-2.6c0-14.5,8-18.8,19.2-18.8h1.1
			c8.5,0,12.4,2.6,14.4,6.9h0.3V56.7h9V107.8z M484.2,89.2v-1.4c0-10-4.6-11.7-12.9-11.7c-8.4,0-12.9,1.7-12.9,11.7v1.3
			c0,10,4.5,11.7,12.9,11.7C479.6,100.8,484.2,99.1,484.2,89.2z"
        />
        <path d="M509.1,56.7v7.2H500v-7.2H509.1z M509.1,69.1v38.7H500V69.1H509.1z" />
        <path
          d="M555.4,107.8h-9v-6.2h-0.3c-1.8,4.2-6.2,6.9-15.6,6.9c-10.4,0-16.2-4-16.2-12v-0.4c0-7.3,4.2-11.1,13.2-11.5l18.8-0.8
			v-0.7c0-6.7-3.6-8.1-10.8-8.1c-6.2,0-9.1,1.5-10.5,5.3h-9.2c1.8-8,7.4-11.9,19.6-11.9h1.2c12.8,0,18.7,3.7,18.7,15.5V107.8z
			 M523.5,96.2c0,4.3,3.5,6,9.6,6c8.5,0,13.2-3.5,13.2-9.7v-2.6l-15.8,0.7c-4.9,0.2-7,1.7-7,5.3V96.2z"
        />
      </g>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="39.8757"
          y1="82.7427"
          x2="149.5805"
          y2="82.7427"
        >
          <stop offset="0" style="stop-color: #ff6800" />
          <stop offset="1" style="stop-color: #ff9000" />
        </linearGradient>
        <path
          class="st0"
          d="M145.6,76h-30.4c-3.2,0-5.1-3.6-3.3-6.3l18.5-25.6c1.2-1.8,0.8-4.4-1.1-5.6l-4.6-3.1
			c-1.8-1.2-4.3-0.8-5.6,1.1l-21,29.3c-1.6,2.4-5.1,2.4-6.7,0L70.1,36.4c-1.2-1.8-3.8-2.3-5.6-1.1l-4.6,3.1
			c-1.8,1.2-2.3,3.8-1.1,5.6l18.8,25.6c1.8,2.7-0.1,6.3-3.3,6.3H43.9c-2.2,0-4,1.8-4,4v5.6c0,2.2,1.8,4,4,4h30.4
			c3.2,0,5.1,3.6,3.3,6.3l-18.5,25.4c-1.2,1.8-0.8,4.4,1.1,5.6l4.6,3.1c1.8,1.2,4.3,0.8,5.6-1.1l21-29.1c1.6-2.4,5.1-2.4,6.7,0
			l21,29.1c1.2,1.8,3.8,2.3,5.6,1.1l4.6-3.1c1.8-1.2,2.3-3.8,1.1-5.6L111.9,96c-1.8-2.7,0.1-6.3,3.3-6.3h30.4c2.2,0,4-1.8,4-4V80
			C149.6,77.8,147.8,76,145.6,76z M100.8,83.7l-9.7,5.6c-0.7,0.4-1.7-0.1-1.7-1V77.1c0-0.9,0.9-1.4,1.7-1l9.7,5.6
			C101.6,82.2,101.6,83.3,100.8,83.7z"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'SunMediaLogoDark',
  data() {
    return {
      id: `circle_${Math.floor(Math.random() * 10000 + 1)}`,
    };
  },
};
</script>
<style scoped type="text/css">
.st0 {
  fill: url(#SVGID_1_);
}
</style>
