<template>
  <div>
    <router-link
      v-for="(context, index) in configTabs"
      :key="`${index}-tab`"
      class="w-auto px-4 mb-1 mr-2 font-normal whitespace-no-wrap cursor-pointer md:mb-0 hover:text-orange-600"
      :class="{ 'font-bold text-orange-600': isForcedActiveClass(context.to) }"
      :active-class="'font-bold text-orange-600'"
      :to="{ name: getNameRoute(context.to) }"
    >
      {{ context.name }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CONTEXTS } from '@/model/shared/contexts';
import { ROLES } from '@/model/shared/roles';
import { USER } from '@/store/modules/auth/keys';
import { CONTEXT_BOARD_BASE } from '@/model/shared/constants';

export default {
  name: 'ContextSelector',
  props: {
    activeTab: {
      type: [String, Object],
      default: () => null,
    },
  },
  computed: {
    ...mapGetters({
      user: USER,
    }),
    rolesAuth() {
      return this.user?.contextRoles.find(contextRole => contextRole.context === CONTEXTS.CORE.id);
    },
    isAdminUser() {
      return this.rolesAuth?.role === ROLES.ADMIN.id;
    },
    configTabs() {
      return this.isAdminUser
        ? [
            {
              name: 'Workspaces',
              to: 'workspaces',
            },
            {
              name: 'Users',
              to: 'users',
            },
          ]
        : [
            {
              name: 'Workspaces',
              to: 'workspaces',
            },
          ];
    },
  },

  methods: {
    getNameRoute(routeEntity) {
      return `${CONTEXT_BOARD_BASE}-${routeEntity}`;
    },
    isForcedActiveClass(contextTo) {
      return this.activeTab !== null && contextTo === this.activeTab;
    },
  },
};
</script>
