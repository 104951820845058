var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.configTabs, function (context, index) {
      return _c(
        "router-link",
        {
          key: `${index}-tab`,
          staticClass:
            "w-auto px-4 mb-1 mr-2 font-normal whitespace-no-wrap cursor-pointer md:mb-0 hover:text-orange-600",
          class: {
            "font-bold text-orange-600": _vm.isForcedActiveClass(context.to),
          },
          attrs: {
            "active-class": "font-bold text-orange-600",
            to: { name: _vm.getNameRoute(context.to) },
          },
        },
        [_vm._v(" " + _vm._s(context.name) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }