<template>
  <header class="w-full flex flex-row justify-between items-center py-2">
    <div class="w-1/2 flex flex-col justify-start items-start md:flex-row md:items-center md:mr-2">
      <div class="flex flex-row justify-start items-center">
        <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_LIGHT" img-class="custom-brand w-48" />
        <sun-media-logo-dark v-else class="text-gray-700" />
      </div>
      <context-selector
        :active-tab="activeTab"
        class="flex flex-row justify-between pt-2 items-baseline mt-4 md:mt-0 md:ml-4"
      />
    </div>
    <div class="relative w-1/2 flex justify-end flex-1 p-2">
      <asterix-user-menu />
    </div>
  </header>
</template>

<script>
import AsterixUserMenu from './AsterixUserMenu';
import ContextSelector from '../../atoms/ContextSelector/ContextSelector';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';

export default {
  name: 'AsterixHeader',
  components: {
    ContextSelector,
    AsterixUserMenu,
    CustomBrandImg,
    SunMediaLogoDark: () => import('../../icons/SunMediaLogoDark'),
  },
  props: {
    activeTab: {
      type: [String, Object],
      default: () => null,
    },
  },
  data() {
    return {
      customDomain: isCustomDomain(),
      customImgTypes,
    };
  },
};
</script>
<style scoped>
.custom-brand {
  max-height: 100px;
}
</style>
