var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-full min-h-screen overflow-y-auto relative" },
    [
      _c(
        "div",
        {
          staticClass: "container mx-auto relative px-6 md:px-12 py-4 md:py-12",
        },
        [
          _c("asterix-header"),
          _c(
            "section",
            {
              staticClass:
                "relative flex flex-col justify-start items-start w-full bg-white mb-2 p-2 pb-8 sm:p-3 sm:pb-8 rounded-lg shadow-md text-left sm:mt-1 md:mt-2 lg:mt-4",
            },
            [
              _vm.user && _vm.user
                ? [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-1 flex flex-wrap px-2 md:px-4 lg:px-8",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "relative w-full flex flex-col" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full mx-auto mt-1 md:mt-2 text-left",
                              },
                              [
                                _c(
                                  "h2",
                                  {
                                    staticClass:
                                      "text-2xl text-left font-bold leading-tight text-orange-500 sm:text-3xl sm:leading-9 sm:truncate",
                                  },
                                  [_vm._v(" " + _vm._s(_vm.user.name) + " ")]
                                ),
                                _vm.user.role
                                  ? [
                                      _c(
                                        "sun-badge",
                                        {
                                          staticClass:
                                            "text-xs md:text-sm ml-2",
                                          attrs: { color: "yellow" },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.user.role) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex text-left text-sm mt-2 pr-4 md:pr-40",
                              },
                              [_vm._v(" " + _vm._s(_vm.i18nTexts.info) + " ")]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "flex flex-col lg:flex-row w-full mt-8",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex-1 flex flex-col overflow-hidden mr-0 lg:mr-8 xl:mr-16",
                              },
                              [
                                _c(
                                  "card-form",
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.i18nTexts.form.title) +
                                          " "
                                      ),
                                    ]),
                                    _c(
                                      "template",
                                      { slot: "form" },
                                      [
                                        _c(
                                          "sun-form",
                                          { on: { submit: _vm.save } },
                                          [
                                            _c("form-row", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "left",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "sun-label-group",
                                                          {
                                                            attrs: {
                                                              text: "Name",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asterix-input",
                                                              {
                                                                attrs: {
                                                                  id: "edit-profile",
                                                                  type: "text",
                                                                  name: "name",
                                                                  required:
                                                                    "required",
                                                                  minlength: 3,
                                                                  maxlength: 50,
                                                                  placeholder:
                                                                    "Name",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userName,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.userName =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "userName",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "right",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "sun-label-group",
                                                          {
                                                            attrs: {
                                                              text: "Email",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asterix-input",
                                                              {
                                                                attrs: {
                                                                  id: "edit-email",
                                                                  type: "email",
                                                                  name: "email",
                                                                  required:
                                                                    "required",
                                                                  disabled: "",
                                                                  readonly: "",
                                                                  "text-error":
                                                                    _vm
                                                                      .errorsForm
                                                                      .email ||
                                                                    _vm
                                                                      .i18nErrors
                                                                      .validEmail,
                                                                  error:
                                                                    !!_vm
                                                                      .errorsForm
                                                                      .email,
                                                                  "validate-on-blur":
                                                                    "",
                                                                  pattern:
                                                                    "^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
                                                                  placeholder:
                                                                    "user@agency.com",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.userEmail,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.userEmail =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "userEmail",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                2191058490
                                              ),
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex mt-4 w-full",
                                              },
                                              [
                                                _c("save-button", {
                                                  attrs: {
                                                    loading: _vm.loading,
                                                    text: _vm.i18nTexts.form
                                                      .saveButton,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _c(
                                  "card-form",
                                  { staticClass: "mb-10" },
                                  [
                                    _c("template", { slot: "title" }, [
                                      _vm._v("Reset Password"),
                                    ]),
                                    _c(
                                      "template",
                                      { slot: "form" },
                                      [
                                        _c(
                                          "sun-form",
                                          { on: { submit: _vm.passwordSave } },
                                          [
                                            _c("form-row", {
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "left",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "sun-label-group",
                                                          {
                                                            attrs: {
                                                              text: "Current Password",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "asterix-input",
                                                              {
                                                                attrs: {
                                                                  id: "current-password",
                                                                  type: "password",
                                                                  "min-strength": 0,
                                                                  name: "currentPassword",
                                                                  required:
                                                                    "required",
                                                                  placeholder:
                                                                    "Current Password",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.currentPassword,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.currentPassword =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "currentPassword",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                false,
                                                2197730323
                                              ),
                                            }),
                                            _c("asterix-new-password-input", {
                                              attrs: {
                                                required: "required",
                                                "auto-validate":
                                                  _vm.autoValidatePassword,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.autoValidatePassword = true
                                                },
                                              },
                                              model: {
                                                value: _vm.newPassword,
                                                callback: function ($$v) {
                                                  _vm.newPassword = $$v
                                                },
                                                expression: "newPassword",
                                              },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "flex mt-4 w-full",
                                              },
                                              [
                                                _c("save-button", {
                                                  attrs: {
                                                    loading:
                                                      _vm.loadingPassword,
                                                    text: "Change",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "w-auto text-center lg:mt-8" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "inline-flex flex-col items-center justify-center mt-4 lg:mt-0 p-8 rounded-lg bg-gray-200 shadow-lg",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bg-gray-400 rounded-full px-4 py-4",
                                      },
                                      [
                                        _c(_vm.icon, {
                                          tag: "component",
                                          staticClass: "w-8 text-gray-700",
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-base text-gray-800 mt-4 font-bold cursor-default",
                                      },
                                      [_vm._v(_vm._s(_vm.user.name))]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "text-sm text-gray-700 mt-px font-bold",
                                        attrs: {
                                          href: `mailto:${_vm.user.email}`,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.user.email) + " "
                                        ),
                                      ]
                                    ),
                                    _vm.newActions.length > 0
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-sm text-gray-700 mt-4 mb-2 text-right font-bold",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.i18nTexts.contexts) +
                                                  ": "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "w-full flex flex-wrap items-center justify-center",
                                            },
                                            [
                                              _vm._l(
                                                _vm.contextRoles,
                                                function (context) {
                                                  return [
                                                    _c(
                                                      "sun-badge",
                                                      {
                                                        key: context.context,
                                                        staticClass:
                                                          "sun-badge-item px-1 mb-1 text-xs text-gray-500 whitespace-no-wrap truncate",
                                                        attrs: {
                                                          color: "orange",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.transformNameToValue(
                                                                context.context
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      : _vm._e(),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex pt-24 w-full items-center justify-center flex-col",
                      },
                      [
                        _c("sun-media-logo-dark", { staticClass: "h-16 mb-2" }),
                        _c("sun-loading", {
                          staticClass: "h-20 text-orange-700",
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _c("asterix-footer"),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }