<template>
  <div class="h-full min-h-screen overflow-y-auto relative">
    <div class="container mx-auto relative px-6 md:px-12 py-4 md:py-12">
      <asterix-header />
      <section
        class="relative flex flex-col justify-start items-start w-full bg-white mb-2 p-2 pb-8 sm:p-3 sm:pb-8 rounded-lg shadow-md text-left sm:mt-1 md:mt-2 lg:mt-4"
      >
        <template v-if="user && user">
          <div class="flex-1 flex flex-wrap px-2 md:px-4 lg:px-8">
            <div class="relative w-full flex flex-col">
              <div class="w-full mx-auto mt-1 md:mt-2 text-left">
                <h2
                  class="text-2xl text-left font-bold leading-tight text-orange-500 sm:text-3xl sm:leading-9 sm:truncate"
                >
                  {{ user.name }}
                </h2>
                <template v-if="user.role">
                  <sun-badge color="yellow" class="text-xs md:text-sm ml-2">
                    {{ user.role }}
                  </sun-badge>
                </template>
              </div>
              <div class="flex text-left text-sm mt-2 pr-4 md:pr-40">
                {{ i18nTexts.info }}
              </div>
            </div>
            <div class="flex flex-col lg:flex-row w-full mt-8">
              <div class="flex-1 flex flex-col overflow-hidden mr-0 lg:mr-8 xl:mr-16">
                <card-form>
                  <template slot="title">
                    {{ i18nTexts.form.title }}
                  </template>
                  <template slot="form">
                    <sun-form @submit="save">
                      <form-row>
                        <template #left>
                          <sun-label-group text="Name">
                            <asterix-input
                              id="edit-profile"
                              v-model="userName"
                              type="text"
                              name="name"
                              required="required"
                              :minlength="3"
                              :maxlength="50"
                              placeholder="Name"
                            />
                          </sun-label-group>
                        </template>
                        <template #right>
                          <sun-label-group text="Email">
                            <asterix-input
                              id="edit-email"
                              v-model="userEmail"
                              type="email"
                              name="email"
                              required="required"
                              disabled
                              readonly
                              :text-error="errorsForm.email || i18nErrors.validEmail"
                              :error="!!errorsForm.email"
                              validate-on-blur
                              pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                              placeholder="user@agency.com"
                            />
                          </sun-label-group>
                        </template>
                      </form-row>
                      <div class="flex mt-4 w-full">
                        <save-button :loading="loading" :text="i18nTexts.form.saveButton" />
                      </div>
                    </sun-form>
                  </template>
                </card-form>

                <card-form class="mb-10">
                  <template slot="title">Reset Password</template>
                  <template slot="form">
                    <sun-form @submit="passwordSave">
                      <form-row>
                        <template #left>
                          <sun-label-group text="Current Password">
                            <asterix-input
                              id="current-password"
                              v-model="currentPassword"
                              type="password"
                              :min-strength="0"
                              name="currentPassword"
                              required="required"
                              placeholder="Current Password"
                            />
                          </sun-label-group>
                        </template>
                      </form-row>
                      <asterix-new-password-input
                        v-model="newPassword"
                        required="required"
                        :auto-validate="autoValidatePassword"
                        @change="autoValidatePassword = true"
                      />
                      <div class="flex mt-4 w-full">
                        <save-button :loading="loadingPassword" text="Change" />
                      </div>
                    </sun-form>
                  </template>
                </card-form>
              </div>
              <div class="w-auto text-center lg:mt-8">
                <div
                  class="inline-flex flex-col items-center justify-center mt-4 lg:mt-0 p-8 rounded-lg bg-gray-200 shadow-lg"
                >
                  <div class="bg-gray-400 rounded-full px-4 py-4">
                    <component :is="icon" class="w-8 text-gray-700" />
                  </div>
                  <span class="text-base text-gray-800 mt-4 font-bold cursor-default">{{ user.name }}</span>
                  <a :href="`mailto:${user.email}`" class="text-sm text-gray-700 mt-px font-bold">
                    {{ user.email }}
                  </a>
                  <template v-if="newActions.length > 0">
                    <span class="text-sm text-gray-700 mt-4 mb-2 text-right font-bold">{{ i18nTexts.contexts }}: </span>
                    <div class="w-full flex flex-wrap items-center justify-center">
                      <template v-for="context in contextRoles">
                        <sun-badge
                          :key="context.context"
                          color="orange"
                          class="sun-badge-item px-1 mb-1 text-xs text-gray-500 whitespace-no-wrap truncate"
                        >
                          {{ transformNameToValue(context.context) }}
                        </sun-badge>
                      </template>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex pt-24 w-full items-center justify-center flex-col">
            <sun-media-logo-dark class="h-16 mb-2" />
            <sun-loading class="h-20 text-orange-700" />
          </div>
        </template>
      </section>
      <asterix-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { ERRORS } from '@/i18n/forms/errors';
import { COMMON_TEXTS } from '@/i18n/commonTexts';
import { updateProfile } from '@/services/modules/Core/profile';
import { loggedInResetPassword } from '@/services/modules/Auth';
import metaInfo from '@/mixins/common/metaInfo';
import { ICONS } from '@/model/shared/icons';
import { capitalize } from '@/utils/text/capitalize';
import SaveButton from '@/components/atoms/SaveButton';
import CardForm from '@/components/atoms/CardForm';
import AsterixFooter from '@/components/molecules/shared/AsterixFooter';
import AsterixHeader from '@/components/molecules/shared/AsterixHeader';
import SunMediaLogoDark from '@/components/icons/SunMediaLogoDark';
import { PERMISSIONS, USER, UPDATE_USER } from '@/store/modules/auth/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import { getNewActions } from './helper';
import AsterixNewPasswordInput from '@/components/atoms/AsterixNewPasswordInput';

export default {
  name: 'IndexProfile',
  components: {
    FormRow,
    SaveButton,
    AsterixFooter,
    CardForm,
    SunMediaLogoDark,
    AsterixHeader,
    AsterixInput,
    AsterixNewPasswordInput,
  },
  mixins: [metaInfo],
  data: () => ({
    i18nErrors: ERRORS.en,
    i18nTexts: COMMON_TEXTS.en.profile,
    errorsForm: { email: null, password: null },
    loading: false,
    loadingPassword: false,
    newActions: [],
    contextRoles: CONTEXTS,
    userName: null,
    userEmail: null,
    currentPassword: '',
    newPassword: '',
    autoValidatePassword: false,
  }),
  computed: {
    ...mapGetters({
      user: USER,
      actions: PERMISSIONS,
    }),
    icon() {
      return this.user && ICONS[this.user.role] ? ICONS[this.user.role] : () => import('@/components/icons/UserSvg');
    },
  },
  watch: {
    actions() {
      this.setNewActions();
    },
  },
  created() {
    this.setNewActions();
    this.userName = this.user.name;
    this.userEmail = this.user.email;
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST, reloadProfile: USER, updateProfileToStore: UPDATE_USER }),
    metaTitleReplacement() {
      return this.user?.name || '';
    },
    transformNameToValue(param) {
      const strVal = param.toLowerCase().replace('_', ' ');
      return capitalize(strVal);
    },
    async save({ form, valid }) {
      if (!valid) return;
      try {
        this.loading = true;
        const objectToPut = {
          name: form.name.value,
          email: form.email.value,
        };
        const { data } = await updateProfile(objectToPut);
        await this.updateProfileToStore(objectToPut);
        this.createToast(Toast.success('Profile updated', data.message));
        // True to force reload profile
        this.reloadProfile(true);
      } catch (e) {
        this.createToast(Toast.error('Profile not updated', e.message));
      } finally {
        this.loading = false;
      }
    },
    async passwordSave({ valid }) {
      if (!valid) return;

      this.loadingPassword = true;
      try {
        await loggedInResetPassword(this.currentPassword, this.newPassword);
        this.createToast(Toast.success('Password updated', 'The new password has been updated successfully.'));
      } catch (error) {
        this.createToast(Toast.error('Password not updated', error.message));
      } finally {
        this.restartPasswordForm();
        this.loadingPassword = false;
      }
    },
    restartPasswordForm() {
      this.newPassword = '';
      this.currentPassword = '';
    },
    setNewActions() {
      if (Object.keys(this.actions).length > 0) {
        const actions = getNewActions(this.actions);
        this.$set(this, 'newActions', actions);
      }
    },
  },
};
</script>
