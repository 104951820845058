var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "w-full flex flex-row justify-between items-center py-2" },
    [
      _c(
        "div",
        {
          staticClass:
            "w-1/2 flex flex-col justify-start items-start md:flex-row md:items-center md:mr-2",
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-row justify-start items-center" },
            [
              _vm.customDomain
                ? _c("custom-brand-img", {
                    attrs: {
                      type: _vm.customImgTypes.BG_LIGHT,
                      "img-class": "custom-brand w-48",
                    },
                  })
                : _c("sun-media-logo-dark", { staticClass: "text-gray-700" }),
            ],
            1
          ),
          _c("context-selector", {
            staticClass:
              "flex flex-row justify-between pt-2 items-baseline mt-4 md:mt-0 md:ml-4",
            attrs: { "active-tab": _vm.activeTab },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "relative w-1/2 flex justify-end flex-1 p-2" },
        [_c("asterix-user-menu")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }